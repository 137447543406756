import { Controller } from '@hotwired/stimulus'
import TomSelect from "tom-select";
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['citySelect', 'countrySelect']
  static values = {
    url: String,
    litePlaceholder: { type: String, default: 'false' },
    allowCreate: { type: Boolean, default: false },
    defaultCountryId: Number
  }

  config = {
    plugins: ['clear_button'],
    loadThrottle: 300,
    valueField: 'value',
    labelField: 'text',
    highlight: true,
    onDropdownClose: this.selectHandler(),
    placeholder: this.placeholder,
    sortField: {
      field: "text",
      direction: "asc"
    },
    load: (q, callback) => this.search(q, callback)
  }

  connect() {
    this.destroyTomSelect()
    this.performAllowCreate()
    this.select = new TomSelect(this.citySelectTarget, this.config)
  }

  performAllowCreate() {
    if (this.allowCreateValue) {
      this.config.create = true
    }
  }

  async search(q, callback) {
    const countrySelect = this.countrySelectTarget;
    const countryId = countrySelect ? countrySelect.value : this.defaultCountryIdValue;
    const response = await get(this.urlValue, {
      query: { q: q, country_id: countryId },
      responseKind: 'json'
    })

    if (response.ok) {
      let list = await response.json
      list = list.map(item => {
        return {
          value: item.value,
          text: item.text
        }
      })
      callback(list)
    } else {
      console.log(response)
      callback()
    }
  }

  selectHandler() {
    return () => {
      if (this.select) this.select.blur()
    }
  }

  changeCountry() {
    if (this.select) {
      this.select.clear(); // Очищення обраного значення
      this.select.clearOptions(); // Очищення варіантів у списку
    }
  }

  get placeholder() {
    if (this.litePlaceholderValue == 'true') {
      return I18n.service_requests.form.placeholder.city_lite
    } else {
      return I18n.service_requests.form.placeholder.city
    }
  }

  destroyTomSelect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  disconnect() {
    this.destroyTomSelect()
    if (this.countrySelect) {
      this.countrySelect.removeEventListener('change', this.handleCountryChange.bind(this));
    }
  }
}
